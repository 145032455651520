.card {
  background-color: #dde4ff;
  border-style: solid;
  border-width: 5px;
  border-color: #475587;
  border-radius: 20px;
  text-align: center;
  padding: 3%;
  height: 100%;
  cursor: pointer;
  width: 100%;
}
.image {
  max-width: 100%;
  max-height: 8vh;
}
.text {
  text-align: center;
  margin-top: 0;
  font-size: 3vw;
  white-space: nowrap;
}
