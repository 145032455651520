.date-picker {
  display: flex;
  align-items: center;
}

.date-divider {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.date-range-title {
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}
