.counter {
  background-color: #f5f5f5;
  padding: 20px 0 5px;
  border-radius: 5px;
  text-align: center;
}

.count-title {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.count-text-sub {
  font-size: 15px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.icon {
  margin: 0 auto;
  float: none;
  display: table;
  color: #4ad1e5;
}

.assessment-title {
  margin-top: 3rem;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.indicator {
  margin-top: 1.5rem;
  margin-left: 1rem;
}

.change-text-green {
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 14px;
  color: #008000;
}

.change-text-red {
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 14px;
  color: #ff0000;
}

.configure-exam-btn {
  float: right;
  font-size: 18;
  width: 11rem;
  border: none !important;
  border-radius: 10px;
  height: 2.5rem;
  font-weight: 700;
  background-color: #007bff;
  color: white;
  margin-top: 4rem;
  margin-right: 1.5rem;
  justify-content: right;
}

.configure-exam-btn:hover {
  background-color: #0066ff;
  transition: 0.2s;
}
