.wordGroup {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 4px solid #f0ad4e;
  position: relative;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #f9e785;
}
