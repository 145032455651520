.distribution-modal {
  .modal-content {
    min-height: 32.5rem;
    min-width: 38rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    border-radius: 2px;
  }
  .modal-body {
    color: black;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    letter-spacing: 0.3px;
  }
}

.header-modal-text {
  color: black;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

.disclaimer-text {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 0.5rem;
}

.field-row {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.category-text {
  float: left;
  color: black;
  line-height: 2.25rem;
  font-weight: 700;
  font-size: 18px;
}

.input-row {
  float: right;
  width: 5.75rem;
  height: 2.25rem;
  border: 1px solid black;
  border-radius: 6px;
  margin-right: 10rem;
}

.dist-val {
  font-size: 18;
  font-weight: 400;
  text-align: center;
  line-height: 2rem;
  border-right: 1px solid black;
  height: 2.125rem;
}

.increment-row,
.decrement-row {
  height: 1.0625rem;
}
.increment-row {
  border-bottom: 1x solid black;
}
.decrement-row {
  border-top: 1px solid black;
}

.increment-btn,
.decrement-btn {
  width: 3rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 1.0625rem;
}

.plus,
.minus {
  width: 100%;
  font-weight: 600;
  font-size: 12px;
}

.total-val {
  font-size: 18;
  font-weight: 400;
  padding-left: 1rem;
  float: right;
}

.pm-wrapper-col {
  float: right;
}

.footer {
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  height: 7.5rem;
  text-align: center;
}

.total-row {
  margin-top: 1rem;
}

.configure-exam-button {
  margin-top: 1.5rem;
  border: none !important;
  border-radius: 11px;
  text-align: center;
  height: 2.4rem;
  width: 6.5rem;
  font-weight: 700;
  font-size: 18px;
  background-color: #007bff;
  color: white;
}

.configure-exam-button:hover {
  background-color: #0066ff;
  transition: 0.25s;
}

.valid-alert,
.invalid-alert {
  margin-top: 1.75rem;
  height: 4rem;
  justify-content: center;
  text-align: center;
}

.valid-message,
.invalid-message {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.5rem;
}

.info-icon {
  fill: #0366d6;
  margin-top: 0.35rem;
  height: 1rem;
  width: 0.875rem;
}

.info-wrapper {
  float: right;
  justify-content: center;
}

.tooltip-sdg-arrow,
.tooltip-sdg {
  max-width: none !important;
}
