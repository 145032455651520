.cardGroup {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 4px solid #f0ad4e;
  position: relative;
  margin: 0 auto;
  &.active {
    border: 10px solid #2096ff;
  }
  border-radius: 10%;
  background-color: #f9e785;
  .completed {
    position: absolute;
    left: 3vw;
    right: 0;
    top: 7vh;
    z-index: 999;
    font-size: 8vw;
    color: #008000;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    padding: 5%;
  }
}

.cardGroupCol {
  padding-left: 0;
  padding-right: 0;
  img {
    max-width: 100%;
    max-height: 7vh;
    margin-bottom: 0.5vh;
    border-radius: 5px;
  }
}

.cardTitle {
  width: 15vw;
  margin: 10px auto 0;
  .text {
    margin: 0 auto;
    width: 75%;
    text-align: center;
    font-size: 2vw;
    background-color: white;
    border: 5px solid #2a6496;
    border-radius: 15px;
    color: #2a6496;
    font-weight: bold;
  }
}

.inactive {
  opacity: 0.3;
}
